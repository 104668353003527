import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 2`}</h1>
    <p>{`A lot of different styles in this week. It starts with half an hour ambient from the amazing Malibu. It’ll then take you on some 175 bpm breaks with a lot of acid, and will put you then back on earth with fresh sounds from Konduku.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180" height="180" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/741400516&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    <h1>{`TANK mix: Malibu`}</h1>
    <p>{`Back in December 2019 I saw Malibu perform a liveset in De School. It was one of the most intense experiences I’ve had with music ever. This 30 minute ambient set will let you feel something like that.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://mmmmalibu.bandcamp.com/album/one-life-2"
      }}>{`Malibu - One Life`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180" height="180" src="https://www.youtube.com/embed/qtSJA_U4W1U" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`Squarepusher – Nervelevers`}</h1>
    <p>{`Squarepusher is back! His new album ‘Be Up A Hello’ will be released on 31 January. After a five year break this is the Squarepusher how he used to be, fine vintage hardware sounds from the 90’s.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://bleep.com/release/156702-squarepusher-be-up-a-hello"
      }}>{`Bleep`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=954903777/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://nousklaer.bandcamp.com/album/white-heron">
    White Heron by Konduku
  </a>
    </iframe>
    <h1>{`Konduku - White Heron`}</h1>
    <p>{`During ADE I saw Konduku playing during Breakfast club. He plays one of the best odd-groove based techno. This album will show a glimp of that, sounds of snappy blips with mesmerising depth.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://nousklaer.bandcamp.com/album/white-heron"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      